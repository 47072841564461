import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Our breakpoints are based on screen widths where our content starts to break. `}<strong parentName="p">{`Our breakpoints may change as we move more of the product into responsive layouts.`}</strong></p>
    <h2>{`Breakpoint and up... 🛫`}</h2>
    <p>{`In most cases, breakpoints get used with the `}<inlineCode parentName="p">{`min-width`}</inlineCode>{` media query. This means that when using a responsive utility class, the class becomes "enabled" from the breakpoint on upwards. Or from the browser's perspective, when the browser's width `}<strong parentName="p">{`is`}</strong>{` the breakpoint or `}<strong parentName="p">{`wider`}</strong>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Breakpoint`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Syntax`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Is enabled...`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`from `}<inlineCode parentName="td">{`0px`}</inlineCode>{` upwards ->`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Extra-extra-small`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`xxs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`from `}<inlineCode parentName="td">{`320px`}</inlineCode>{` upwards ->`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Extra-small`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`xs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`from `}<inlineCode parentName="td">{`412px`}</inlineCode>{` upwards ->`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Small`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`sm`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`from `}<inlineCode parentName="td">{`640px`}</inlineCode>{` upwards ->`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Medium`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`md`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`from `}<inlineCode parentName="td">{`768px`}</inlineCode>{` upwards ->`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Large`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`lg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`from `}<inlineCode parentName="td">{`1024px`}</inlineCode>{` upwards ->`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Extra-large`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`xl`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`from `}<inlineCode parentName="td">{`1280px`}</inlineCode>{` upwards ->`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`A responsive utility class stays enabled `}<strong parentName="p">{`unless`}</strong>{` it gets overridden with another responsive utility class that has a higher breakpoint. Here the example visualized: `}</p>
    <pre><code parentName="pre" {...{}}>{`| 0px ->
          | 320px ->
          | xxs
                    | 412px ->
                    | xs
                              | 640px ->  
                              | sm
                                        | 768px ->  
                                        | md 
                                                  | 1024px ->  
                                                  | lg
                                                              | 1280px ->
                                                              | xl
`}</code></pre>
    <p>{`Using breakpoints with the `}<inlineCode parentName="p">{`min-width`}</inlineCode>{` media query works great for "mobile first". Design for mobile devices as a default, then if needed, tweak it for wider desktop screens using responsive utility classes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      